.bottleWrapper {
  align-items: center;
  display: flex;
  font-size: 9px;
  justify-content: center;
  max-width: min(432px, 90vw);
  object-fit: cover;
  position: relative;

  span {
    position: absolute;
    text-transform: none;
    transform: rotate(-90deg);
    transform-origin: left top;
  }

  .perfumeName {
    color: rgba(0, 0, 0, 0.9);
    font-family: var(--font-accent-medium);
    font-size: min(2.8vmin, 12px);
    font-style: italic;
    font-weight: 600;
    left: 52.5%;
    letter-spacing: 0.02em;
    text-align: left;
    top: 67.5%;
    color-scheme: only light;
  }

  .customerName {
    color: rgba(0, 0, 0, 0.6);
    font-size: min(1.6vmin, 8px);
    left: 59%;
    letter-spacing: 0.02em;
    line-height: 100%;
    top: 58.4%;
    color-scheme: only light;
  }
}

.bottle-image {
  border-radius: 8%;
}


@media screen and (max-width: 413px) {
  .bottle-button {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .grid-area-bottle {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: center;
    margin: auto 0;
    max-width: 67vw;
    padding-top: 32px;
    width: 100%;
  }
}

.bottleWrapper__container {
  display: contents;
}

@media screen and (min-width: 1024px) {
  .bottleWrapper__container {
    align-items: center;
    display: flex;
    grid-row: 1/-1;
    justify-content: center;
  }

  .bottleWrapper {
    height: unset;
    margin: 0;
    max-width: 360px;
  }

  .grid-area-bottle {
    column-gap: 64px;
    display: grid;
    grid-template-columns: minmax(40%, 40vmin) 1fr 1fr;
    grid-template-rows: 3fr 109px minmax(109px, 1fr) 1fr 1fr;
    margin: auto;
    max-height: 70vh;
    min-height: 637px;
    min-width: 75vw;
    row-gap: 32px;
  }

  .bottle-input {
    grid-column: 2/-1;
    grid-row: 2;
  }

  .bottle-button {
    grid-column: 2;
    grid-row: 3;
    padding: 0;
  }

  .bottleWrapper .perfumeName {
    top: 67.5%;
  }

  .bottleWrapper .customerName {
    top: 58.5%;
  }
}
