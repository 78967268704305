@font-face {
  font-family: 'PP Neue Montreal';
  src: url('../../assets/fonts/PPNeueMontreal-Regular.woff2') format('woff2'),
    url('../../assets/fonts/PPNeueMontreal-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('../../assets/fonts/PPNeueMontreal-Medium.woff2') format('woff2'),
    url('../../assets/fonts/PPNeueMontreal-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

/* @font-face {
  font-family: 'Swear Display';
  src: url('/src/assets/fonts/SwearDisplayDemo-Italic.otf');

  font-display: swap;
} */

/* @font-face {
  font-family: 'Swear Display Medium';
  src: url('/src/assets/fonts/SwearDisplayDemo-MediumItalic.otf');
     font-display: swap;
} */

@font-face {
  font-family: 'Swear Display Medium';
  src: url('https://use.typekit.net/af/98fdf3/00000000000000007735e25c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/98fdf3/00000000000000007735e25c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/98fdf3/00000000000000007735e25c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3')
      format('opentype');
  font-display: swap;
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

p {
  max-width: 90.5%;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
}

@media screen and (min-width: 1366px) {
  p {
    font-weight: 500;
  }
}

span, h4 {
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

h4 {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  span {
    font-size: 14px;
    letter-spacing: 0.02em;
  }
}
@media screen and (min-width: 1366px) {
  p {
    width: 565px;
    font-size: 14px;
  }

  span {
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 32px;
  }
}

@media screen and (min-width: 1366px) {
  .title {
    font-size: 40px;
  }
}

.subtitle {
  font-family: var(--font-accent-medium), serif;
  font-style: italic;
  font-weight: 600;
  font-size: 32px;
  padding: 0;
  margin: 0;
  line-height: 100%;
  letter-spacing: -0.01em;
}

@media screen and (min-width: 768px) {
  .subtitle {
    font-size: 40px;
    font-weight: 600;
  }
}

input {
  font-size: 32px;
  height: 4rem;
  border: 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  max-width: 90vw;
  border-radius: 0;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    font-size: calc(20px + 0.5vw);
    padding-left: 0.15em;
  }
}

.font-24-110 {
  font-size: 24px;
  font-weight: 400;
  line-height: 110%;
  text-transform: uppercase;
}

.font-20-120 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.font-12-120 {
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
