#final-notes {
  max-width: 520px;
}

.cart-headline {
  grid-column: 2/-1;
}

.cart-total {
  justify-content: space-between;
  width: calc(100% - 32px);
  padding: 24px 0 96px;
  border-top: 1px solid #000;

  @media (max-width: 1365px) {
    grid-row: -2;
    grid-column: -3/-1;
  }
}

@media screen and (min-width: 1024px) {
  #final-notes {
    grid-column: -3/-1;
  }
}

@media screen and (min-width: 1366px) {
  .cart-headline {
    justify-content: center;
  }

  #final-notes {
    grid-column: 2/-1;
    grid-row: 2/5;
  }

  .cart-total {
    width: unset;
    padding: 24px 0 0;
    grid-column: -3/-1;
    grid-row: -3;
  }

  .cart-buttons {
    position: static;
    grid-row: -2;
    grid-column: -3/-1;
  }
}
