@forward 'header';
@forward 'navigation';

.hidden {
  visibility: hidden;
  opacity: 0;
}

.flex-row {
  display: flex;
  gap: 8px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-align {
  align-items: flex-start;
}

.full-width {
  width: 100%;
}

.border-round {
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid black;
}

.section-medium {
  gap: var(--gap-medium);

  > * {
    padding: 0;
    margin: 0;
  }
}

.section-small {
  gap: var(--gap-small);
}

.grid-row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  justify-items: start;
  width: 100%;
  border-bottom: 1px solid #000;
  padding-bottom: 16px;
}

.grid-row-2:last-of-type {
  border: 0;
  padding: 0;
}

.evenly {
  display: flex;
  justify-content: space-evenly;
}

.display-contents {
  display: contents;
}

.page-width {
  width: 100%;
  padding: 0 16px;
}

@media screen and (max-width: 785px) {
  .grid-row-2 {
    width: calc(100% - 32px);
  }
}


/*  B2B Styles  */

.brand-form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .upload-area {
    width: 100%;
  }
}
