.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: inherit;

  &Menu {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

@media screen and (min-width: 1366px) {
  .header {
    height: 96px;
    width: 100%;
    padding: 24px;
  }

  .logo {
    max-width: 330px;
    height: 42px;
    max-height: 42px;
  }
}

#cart-icon-link {
  position: relative;
}

#cart-icon-link::after {
  content: '';
  border: 1px solid #222;
  border-radius: 50%;
  position: absolute;
  top: 55%;
  left: 55%;
  height: 8px;
  width: 8px;
  background-color: #2e7515;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-bottom: 100%;

  /*   border-radius: 0 0 15px 15px; */
  overflow: hidden;
  z-index: 2;
  background: linear-gradient(180deg, #fff 50%, rgb(255 255 255 / 30%) 100%);

  .btn {
    margin: 24px 16px;
  }
}

.dropdown-link {
  display: block;
  text-transform: uppercase;
  font-size: 20px;
  padding: 16px;
  border-top: 1px solid #000;
}

.dropdown-link:nth-child(4) {
  border-bottom: 1px solid #000;
}

.desktop-links {
  display: flex;
  align-items: center;
  line-height: 140%;
  gap: 24px;
}
