#imagecloud__container {
  position: relative;
  min-height: 50vh;
  width: 100%;


  img {
    position: absolute;
    aspect-ratio: 1;
    border: 1px solid black;
    border-radius: 8%;

  }
}

.imagecloud__image-wrapper {
  position: absolute;
  transition: all 0.5s ease-in-out;
  transform: translateX(-50%) translateY(-50%);

  .olfactive-family__label {
    position: absolute;
    top: 70%;
    left: 45%;
    background-color: rgba(255,255,255,0.85);
    padding: 2px 4px;
    border: 1px solid black;
    border-radius: 4px;
    font-size: 12px;
    text-transform: capitalize;
    white-space: nowrap;
    z-index: 20;
  }
}

hr {
  border-bottom: 1px solid black;
}
.iw-0 {

}

.iw-1 {
  transform: translateX(-150%) translateY(50%);
}

.iw-2 {
  transform: translateX(25%) translateY(80%);
}

.iw-3 {
  transform: translateX(-100%) translateY(-200%);
}
.iw-4 {
  transform: translateX(-200%) translateY(-80%);
}

.iw-5 {
  transform: translateX(70%) translateY(-200%);
}
