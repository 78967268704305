.card__grid {
  $page-padding: 8px;
  $column-gap: 8px;
  $row-gap: 8px;

  display: grid;
  grid-auto-rows: calc(
    ((50vw - $page-padding - ($column-gap / 2)) / 4) - ($row-gap * 0.75)
  );
  column-gap: $column-gap;
  row-gap: $row-gap;
  grid-template-columns: repeat(2, 1fr);
  padding: $page-padding;
  width: 100%;

  > div,
  .image__wrapper {
    width: 100%;

    /*     height: 100%; */
    border-radius: 12px;
    overflow: hidden;
    z-index: 1;
    transition: all 0.4s;
  }

  @media screen and (min-width: 1366px) {
    > div,
    .image__wrapper {
      border-radius: 25px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.4s;
  }

  .short {
    grid-row: span 4;
  }

  .tall {
    grid-row: span 5;
  }

  @media screen and (min-width: 1366px) {
  }

  .selectedCard {
    position: relative;
    background-color: var(--color-neon-green);
  }
}

@media screen and (min-width: 768px) {
  .card__grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 5vw;
    gap: 16px;

    .short {
      grid-row: span 4;
    }

    .tall {
      grid-row: span 5;
    }
  }
}

.added-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  text-transform: uppercase;
  position: absolute;
  bottom: 8px;
  left: 8px;
  right: 8px;
  height: 32px;
  background-color: var(--color-neon-green);
  border-radius: 16px;
  z-index: 5;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .added-label {
    font-size: 14px;
    left: 16px;
    bottom: 16px;
    right: 16px;
    height: 55px;
    max-height: 55px;
    border-radius: 34px;
    padding: 0 19px;
  }

  .plus-on-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 19px;
    position: absolute;
    width: 55px;
    height: 55px;
    right: 16px;
    bottom: 16px;
    border-radius: 34px;
    background: var(--color-neon-green);
    z-index: 5;
  }
}
