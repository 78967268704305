.navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem var(--page-padding-mobile);
  width: 100%;

  button {
    padding: 0;
  }
}

.page-count {
  align-items: flex-start;
  border: 1px solid black;
  border-radius: 60px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 8px;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.02em;
  padding: 8px 16px;
}

.bottom-page-overlay {
  background: linear-gradient(
                  180deg,
                  rgb(255 255 255 / 0%) 0%,
                  rgb(255 255 255 / 59.2%) 19.79%,
                  #fff 39.58%
  );
  bottom: 0;
  display: flex;
  height: 112px;
  justify-content: center;
  left: 0;
  padding: 40px 16px 24px;
  position: fixed;
  right: 0;
  transition: all 0.3s;
  z-index: 90;

  a,
  button {
    margin: 0;
  }

  .btn {
    width: 100%;
  }

  .scroll-down-button {
    background-color: rgb(237, 247, 240);
    border: none;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 50%;
    height: 48px;
    width: 48px;
    transition: background-color 0.5s ease;
    min-width: 48px;
    &:hover {
      background-color: powderblue;
    }
  }

}

@media screen and (min-width: 768px) {
  .bottom-page-overlay {
    background: linear-gradient(
                    180deg,
                    rgb(255 255 255 / 0%) 4.43%,
                    rgb(255 255 255 / 0.7281%) 21.35%,
                    #fff 31.25%
    );
    .btn {
      width: unset;
    }

    .continue-btn {
    }

    .scroll-down-button {
      position: fixed;
      right: 3rem;
    }
  }

  .navbar {
    padding: 0.75rem var(--page-padding-desktop);
  }
}
