@use 'base';
@use 'layout';
@use 'pages';

* {
  box-sizing: border-box;
}

body {
  font-family: var(--font-primary), Helvetica, sans-serif;
  margin: 0;
}

a {
  color: #222;
  text-decoration: none;
}

ul,
li {
  list-style: none;
  padding: 0;
}

input,
button,
textarea {
  color: #222;
  font-family: var(--font-primary);
}

.app {
  background-color: #fff;
  min-height: 100vh;
  transition: background-color 0.6s;
}

.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  min-height: calc(100vh - 60px);
  position: relative;
  padding: 0 var(--page-padding-mobile);
}

@media screen and (min-width: 768px) {
  .main {
    gap: var(--gap-xl);
    padding: 0 var(--page-padding-desktop) 64px;
  }
}

@media screen and (min-width: 1366px) {
  .main {
    gap: var(--gap-xl);
    min-height: calc(100vh - 161px);
  }
}

.btn {
  align-items: center;
  align-self: stretch;
  background: #000;
  border: 0;
  border-radius: 2rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 120%;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (min-width: 414px) {
  .btn {
    font-size: 14px;
    padding: 0.75rem 2.5rem;
  }
}

.disabled {
  cursor: default;
  opacity: 0.25;
}

.light {
  background-color: #fff;
  border: 1px solid black;
  color: #000;
}

.page-overlay {
  align-items: center;
  background: linear-gradient(180deg, #fff 37.5%, rgb(255 255 255 / 0%) 100%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  inset: 0;
  padding: 40px 16px 0;
  position: absolute;
  z-index: 2;

  span {
    margin: 0;
  }

  a {
    color: #000;
    text-decoration: underline;
  }

  .btn {
    align-self: auto;
    margin: 0;
    width: 50%;
  }

  @media screen and (min-width: 768px) {
    .btn {
      width: unset;
    }
  }

  input {
    align-self: auto;
    font-size: 20px;
    text-align: center;
    width: 100%;
  }

  @media screen and (min-width: 1366px) {
    input {
      align-self: auto;
      width: 687px;
    }
  }
}

.terms {
  z-index: 2;
}

.buttons-wrapper {
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;

  button {
    width: 67%;
  }

  button:nth-of-type(1) {
    background-color: #fff;
    border: 1px solid black;
    color: #000;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media screen and (min-width: 768px) {
  .email-input {
    max-width: 50vw;
  }

  .buttons-wrapper {
    max-width: 50vw;
  }
}

.loader {
  animation: rotation 1s linear infinite;
  aspect-ratio: 1;
  border: 2px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 64px;
  margin-top: 1em;
  width: 64px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #ff3217;
  font-size: 12px;
  padding: 0 48px;
  text-align: center;
}
