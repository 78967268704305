#intensity__wrapper {
  display: flex;
  gap: 4px;
  align-items: center;

  .ball {
    width: 16px;
    height: 16px;
    aspect-ratio: 1;
    border: 1px solid black;
    border-radius: 50%;
  }

  .filled {
    background-color: #000;
  }
}