a {
  text-decoration: underline !important;
}

/* Mobile */
.tag {
  font-size: 12px;
  font-weight: 500;
  font-family: 'PP Neue Montreal', Helvetica, sans-serif;
  vertical-align: top;
  line-height: 140%;
}

.options {
  font-size: 2rem;
  display: flex;
  width: 100vw;
  overflow: auto;
  white-space: nowrap;
  gap: 1.5rem;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  h3 {
    font-family: var(--font-accent-medium), serif;
    font-weight: 600;
    font-style: italic;
    display: inline;
    margin: 0;
  }

  .outline {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 25px;
    width: 78.5vw;
    aspect-ratio: 1;
    margin-bottom: 0.5em;
  }
}

@media screen and (max-width: 767px) {
  .options {
    h3 {
      font-size: 7.8vw;
    }
  }

  .top-space {
    padding-top: 64px;
  }

  .option__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-snap-align: center;
    scroll-snap-stop: always;

    &:first-child {
      margin-left: 1.5rem;
    }

    &:last-of-type {
      padding-right: 1.5rem;
    }

    &:nth-of-type(2) .outline {
      border-radius: 250px;
    }

    a {
      -webkit-tap-highlight-color: transparent;
    }
  }

  .outline {
    border-radius: 25px;
    width: 78.5vw;
    height: 78.5vw;
    margin-bottom: 0.5em;
  }

  .optionText {
    white-space: normal;
    width: 80%;
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .top-space {
    padding-top: 40px;
  }

  .options {
    padding-top: 4%;

    h3 {
      font-family: var(--font-accent-medium), serif;
      font-style: italic;
      font-weight: 600;
      font-size: 6.25vw;
      display: inline;
      margin: 0;
    }

  }

  .option__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-snap-align: center;
    scroll-snap-stop: always;

    &:first-child {
      margin-left: 1.5rem;
    }

    &:last-of-type {
      padding-right: 1.5rem;
    }

    &:nth-of-type(2) .outline {
      border-radius: 250px;
    }

    a {
      -webkit-tap-highlight-color: transparent;
    }
  }

  .outline {
    width: 58.3vw;
    aspect-ratio: 1 / 1;
    border-radius: 25px;
  }

  .optionText {
    width: 58%;
    white-space: normal;
    text-align: center;
  }
}

@media screen and (max-width: 1365px) and (orientation: landscape) {
  .options {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    h3 {
      font-size: 2.8vw;
    }

    .outline {
      width: 100%;
      height: unset;
      aspect-ratio: 1 / 1;
    }

    .optionText {
      width: unset;
    }
  }
}

/**
 **
 ** DESKTOP
 **         */

@media screen and (min-width: 1024px) {
  .options {
    max-width: min(1100px, 90vw);
  }
}

@media screen and (min-width: 1366px) {
  .top-space {
    padding-top: 64px;
  }

  .options {
    white-space: initial;
    overflow: unset;
    display: grid;
    gap: 24px;
    width: 100%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 485px;

    h3 {
      font-family: var(--font-accent-medium), serif;
      font-style: italic;
      font-weight: 600;
      line-height: 100%;
      font-size: 1.5em;
      display: inline;
      margin: 0;
    }

    .outline {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: unset;
      aspect-ratio: 1 / 1;
      border: 1px solid #000;
      border-radius: 25px;
    }
  }

  .tag {
    font-weight: 500;
    font-size: 0.44em;
    line-height: 120%;
    letter-spacing: 0.02em;
    vertical-align: top;
    margin-right: 4px;
  }

  .optionText {
    font-size: 0.875rem;
    line-height: 120%;
    letter-spacing: 0.02em;
    width: 247px;
  }
}
