.characteristics-table {
  border: 1px solid #000;
  display: grid;
  font-size: 14px;
  font-weight: 500;
  grid-auto-rows: minmax(10px, auto);
  grid-template-columns: repeat(3, minmax(0, 1fr));
  line-height: 120%;
  padding: 1.25rem 0;
  position: relative;
  row-gap: 40px;
}

.characteristics-table::after {
  border-top: 1px solid black;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  width: 100%;
}

.cell {
  align-items: center;
  border-right: 1px solid #000;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  letter-spacing: 0.02em;
  padding: 0 1.75rem;
  text-transform: uppercase;
}

.cell:nth-of-type(3n) {
  border: 0;
}

.ratio {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 110%;
}

.chart-wrapper {
  width: 66.7%;
}

.donut-list {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  gap: 12px;
  padding: 0 2rem 6rem;
  width: 100%;
}

.donut-list > li {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 120%;
  position: relative;
}

.bar {
  border-radius: 16px;
  height: 16px;
  margin-top: 0.25em;

  /*  &::after {
    position: absolute;
    content: '';
    border-top: 1px solid #000;
    transform: translateY(100%);
    left: 0;
    right: 0;
    top: 24px;
    z-index: -1;
  } */
}

.bar-line {
  top: 76%;
}

@media screen and (min-width: 768px) {
  .chart-wrapper {
    max-width: 50%;
    width: 424px;
  }

  .donut-list {
    font-size: 14px;
    justify-content: center;
    max-width: 50%;
    padding: 0;
    padding-left: 48px;
    width: 569px;
  }

  .bar {
    margin-top: 12px;
  }

  .bar-line {
    top: 82%;
  }
}

.page-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
  position: relative;
  width: 100%;
  margin-bottom: 5rem;
}

section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;

    hr {
      margin-left: 0;
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 6rem;
    }
  }
}

@media screen and (max-width: 768px) {
  section {
    display: flex;
    flex-direction: column;
  }

  .page-section {
    display: contents
  }
}
