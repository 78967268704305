.container__mixingblends {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .card__grid {
    $page-padding: 8px;
    $column-gap: 8px;
    $row-gap: 8px;

    grid-auto-rows: calc(
      (((50vw - $page-padding - ($column-gap / 2))) / 4.02) - ($row-gap)
    );
    padding: $page-padding;
    row-gap: $row-gap;
    column-gap: $column-gap;

    .selectedCard img {
      opacity: 0;
    }

    > div {
      border: 1px solid black;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
      gap: 8px;
    }

    @media screen and (min-width: 1024px) {
      > div {
        padding: 16px 16px 24px;
      }
    }

    > div > div {
      width: 100%;
    }

    span {
      width: 100%;
      text-align: left;
      font-size: 11px;
      line-height: 120%;
      letter-spacing: 0.02em;
      text-transform: initial;
    }

    @media screen and (min-width: 1366px) {
      margin-top: 56px;

      span {
        font-size: 14px;
        width: 90%;
      }
    }

    .blend__name {
      text-transform: uppercase;
      font-size: 14px;
      width: 100%;
      text-align: left;
    }

    @media screen and (min-width: 1366px) {
      .blend__name {
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -0.01em;
      }
    }

    .blend__info {
      display: flex;
      padding-top: 8px;
      flex-direction: column;
      gap: 4px;
    }

    @media screen and (min-width: 1366px) {
      .blend__name {
        padding-bottom: 12px;
      }
    }

    @media screen and (max-width: 396px) {
      grid-auto-rows: calc((50vw) / 4.5);
    }
  }

  .short {
    grid-row: span 6;

    .image__wrapper {
      aspect-ratio: 1 / 1;
    }
  }

  @media screen and (max-width: 374px) {
    .short {
      grid-row: span 6;
    }

    .card__grid .blend__name {
      font-size: 10px;
      margin-bottom: 4px;
    }
  }
  @media screen and (min-width: 768px) {
    .card__grid {
      $column-gap: 16px;
      $row-gap: 16px;

      padding: 0 0 var(--gap-medium);

      /*       grid-auto-rows: calc((((25% - ($column-gap))) / 4.02) - ($row-gap)); */
      grid-auto-rows: calc(
        ((((100vw - 48px) - ($column-gap * 3)) / 4) / (24 / 5)) - 10px
      );
      row-gap: $row-gap;
      column-gap: $column-gap;
    }
  }

  .tall {
    grid-row: span 7;

    .image__wrapper {
      aspect-ratio: 4 / 5;
    }
  }

  @media screen and (max-width: 374px) {
    .tall {
      grid-row: span 6;
    }
  }
}
