.upload-area {
  padding-bottom: 150px;
}

.uploadedImgWrapper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
  overflow: hidden;
}

.uploadedImg {
  object-fit: cover;
  width: 104px;
  height: 6rem;
}

.uploadSlot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25.1vw;
  height: 20vw;
  border-radius: 0.75rem;
  border: 1px solid black;
  position: relative;
  object-fit: cover;
  cursor: pointer;

  .current-selected {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1366px) {
  .uploadSlot {
    width: 208px;
    height: 166px;
  }
}

.imgWrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 0.75rem;
  object-fit: cover;
}

.selected {
  border: 0;
}

.selected::after {
  content: 'Replace';
  position: absolute;
  top: 105%;
  text-decoration: underline;
  cursor: pointer;
}

.remove {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (min-width: 768px) {
  .remove {
    top: 20px;
    right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .upload-area {
    .uploadSlot {
      width: unset;
      height: 100%;
    }

    padding-top: var(--gap-medium);
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 20vw;
    column-gap: 16px;
  }
}
