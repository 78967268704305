:root {
  --font-primary: 'PP Neue Montreal';
  --font-accent-medium: 'Swear Display Medium';
  --color-sea-salt: #d9f2ff;
  --color-neon-green: #e2ff8f;
  --gap-xl: 64px;
  --gap-large: 48px;
  --gap-medium: 32px;
  --gap-small: 16px;

  /* Responsive , screen resolutions */
  --tablet: 768px;
  --desktop: 1366px;

  --page-padding-mobile: 16px;
  --page-padding-desktop: 24px;
}
